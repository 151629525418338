/* Define font-face for Inter font */
@font-face {
  font-family: 'Inter';
  src: url('../public/fonts/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../public/fonts/Inter-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../public/fonts/Inter-Medium.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../public/fonts/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../public/fonts/Inter-Light.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../public/fonts/Inter-ExtraLight.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

body {
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}

.App {
  text-align: center;
  height: 100vh;
  overflow-y: auto;
  background-color: #0e0e0e;
  font-family: 'Inter', sans-serif;
}

.App-nav {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px 0;
  border-width: 1px;
  border-color: rgba(251, 251, 251, 0.5);
  border-style: solid;
  border-radius: 20px;
  margin-bottom: 200px;
}

.nav-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap; /* Permet de passer à la ligne si nécessaire */
}

.nav-content a,
.nav-content button,
.nav-content .nav-item {
  color: white;
  text-decoration: none;
  margin: 0 15px;
  background: none;
  border: none;
  cursor: pointer;
  font-weight: 100;
  font-family: 'Inter', sans-serif;
  font-size: 15px;
}

.nav-content .nav-item {
  transition: transform 0.3s ease;
}

.nav-content .nav-item:hover {
  transform: scale(1.1);
}

.nav-content .logo img {
  height: 40px;
}

.dropdown {
  position: relative;
}

.dropdown-arrow {
  width: 16px;
  height: 16px;
  vertical-align: middle;
}

.dropdown-content {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  margin-top: 2rem;
  margin-left: 1.5rem;
  background-color: rgba(0, 0, 0, 0.8);
  border: 1px solid rgba(255, 255, 255, 0.473);
  border-radius: 10px;
  z-index: 1001;
  min-width: none;
  white-space: nowrap;
}

.dropdown-content a {
  display: flex;
  align-items: center;
  padding: 10px;
  color: white;
  text-decoration: none;
  font-weight: 100;
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  text-align: left;
}

.dropdown-content a img.dropdown-logo {
  margin-right: 10px;
  width: 20px;
  height: 20px;
}

.dropdown-content a:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.action-button.nav-item {
  background-color: transparent;
  border: 1px solid #73a8f0;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 15px;
  font-weight: 700;
  font-family: 'Inter', sans-serif;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.action-button.nav-item:hover {
  background-color: #73a8f0;
  color: #ffffff;
}

.App-header {
  background-image: url('../public/background.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  padding: 20px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.Title h1 {
  font-family: 'Inter', sans-serif;
  font-weight: 300;
  background: linear-gradient(to right, white, #5896ee);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 48px;
}

.Title h3 {
  font-family: 'Inter', sans-serif;
  font-weight: 100;
  color: rgba(255, 255, 255, 0.864);
  font-size: 20px;
}

.highlight {
  font-weight: 700;
  color: #73a8f0;
}

.italic {
  font-style: italic;
}

.redirect-button {
  margin: 40px 0;
  margin-bottom: 130px;
  padding: 12px 22px;
  background: linear-gradient(to right, #78abf3, #5896ee); /* Dégradé de couleur */
  border: 2px solid transparent;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 15px;
  transition: transform 0.3s ease;
}

.redirect-button:hover {
  transform: scale(1.1);
}

.soutien {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.soutien p {
  margin: 0;
  margin-right: 10px;
  font-weight: bold;
  color: white;
  font-family: 'Inter', sans-serif;
  font-weight: 100;
}

.soutien img {
  height: 20px;
  margin-right: 10px;
}

.partners-section {
  text-align: center;
  padding: 10px;
}

.partners-section h2 {
  font-size: 20px;
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  background: linear-gradient(to right, white, #5896ee);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.image-container {
  display: flex; /* Utiliser flexbox pour aligner les images */
  justify-content: center; /* Centrer les images horizontalement */
  margin-top: 50px; /* Espace entre le bouton et les images */
  flex-wrap: wrap; /* Permet de passer à la ligne si nécessaire */
}

.partner-image {
  margin: 10px; /* Espace entre les images */
  width: 150px; /* Ajustez la taille des images selon vos besoins */
  height: auto; /* Maintenir le ratio d'aspect des images */
}

.contact-button {
  padding: 10px 20px; /* Padding interne du bouton */
  font-size: 16px; /* Taille de la police */
  color: white; /* Couleur du texte */
  background: linear-gradient(to right, #5896ee, #78abf3); /* Dégradé de couleur */
  border: none; /* Pas de bordure */
  border-radius: 15px; /* Coins arrondis */
  cursor: pointer; /* Curseur pointeur */
  transition: transform 0.3s ease; /* Transition pour l'effet d'agrandissement */
}

.contact-button:hover {
  transform: scale(1.1); /* Effet d'agrandissement */
}

.highlight-line {
  margin: 20px auto; /* Espace autour du trait */
  width: 70%; /* Largeur du trait */
  height: 2px; /* Hauteur du trait */
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(232, 232, 241, 0.5), rgba(255, 255, 255, 0)); /* Dégradé pour l'effet d'éclairage */
  box-shadow: 0 0 10px rgba(181, 181, 194, 0.5); /* Effet d'éclairage */
}

.App-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.section-title {
  font-size: 30px;
  font-family: 'Inter', sans-serif;
  font-weight: 300;
  margin-bottom: 20px;
  background: linear-gradient(to right, rgb(255, 255, 255), #888888);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section {
  padding: 50px 0;
  width: 100%;
  text-align: center;
  color: white;
}

.AboutUsStatsImage {
  width: 100%;
  max-width: 1000px;
  height: auto;
  margin-top: 10px;
}

.section-content {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  flex-wrap: wrap; /* Permet de passer à la ligne si nécessaire */
}

.section-content p {
  margin: 20px;
  width: 100%;
  max-width: 500px;
  font-family: 'Inter', sans-serif;
  font-weight: 300;
  background: linear-gradient(to right, white, #73a8f0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 30px;
}

.step {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  position: relative;
  flex-direction: column;
}

.circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid white;
  background-color: transparent;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.2rem;
}

.active .circle {
  border: 2px solid #73a8f0;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.progress-bar::before {
  content: '';
  position: absolute;
  top: 15px;
  bottom: 15px;
  width: 2px;
  background-color: #ddd;
  z-index: -1;
}

.step-text {
  position: absolute;
  font-family: 'Inter', sans-serif;
  font-weight: 300;
  font-size: 30px;
  white-space: nowrap;
  width: auto;
}

.step:nth-child(odd) .step-text {
  left: 100px;
  background: linear-gradient(to right, white, #73a8f0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: left;
}

.step:nth-child(even) .step-text {
  right: 100px;
  background: linear-gradient(to right, #73a8f0, white);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: right;
}

.team-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 20px;
  flex-wrap: wrap; /* Permet de passer à la ligne si nécessaire */
}

.team-member {
  text-align: center;
  margin: 10px;
}

.team-photo {
  width: 250px;
  height: 300px;
  border-radius: 10px;
  object-fit: cover;
  object-position: top;
}

.team-info {
  display: block;
  text-align: center;
  text-decoration: none;
  color: white;
  padding: 10px;
  border: 1px solid transparent;
  background-color: #73a8f0;
  border-radius: 30px;
  transition: background-color 0.3s, border-color 0.3s;
  margin-top: 10px;
  margin-bottom: 10px;
}

.team-info:hover {
  background-color: #f0f0f0;
  border-color: #ddd;
  color: black;
}

.team-info p {
  margin: 0px 0;
}

.App-footer {
  text-align: center;
  padding: 20px;
  position: relative;
  bottom: 0;
  width: 100%;
  font-size: 14px;
  color: #73a8f0;
}

/* Media Queries */
@media (max-width: 1024px) {

  .Title {
    margin-top: -150px;
  }

  .soutien {
    margin-top: -100px;
  }

  .step-text {
    position: static; /* Change from absolute to static */
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    font-size: 20px; /* Adjust font size for smaller screens */
    white-space: nowrap;
    width: auto;
    text-align: center; /* Center align the text */
    margin-top: 10px; /* Add margin to separate text from circle */
  }
}

@media (max-width: 768px) {
  .Title h1 {
    font-size: 36px;
  }

  .Title h3 {
    font-size: 18px;
  }

  .redirect-button {
    font-size: 14px;
    padding: 10px 20px;
  }

  .section-title {
    font-size: 24px;
  }

  .section-content p {
    font-size: 24px;
  }

  .circle {
    width: 40px; /* Reduce circle size for smaller screens */
    height: 40px;
    font-size: 1.8rem; /* Adjust font size inside the circle */
  }

  .step-text {
    font-size: 24px;
  }

  .team-photo {
    max-width: 200px;
  }
}

@media (max-width: 480px) {
  .Title h1 {
    font-size: 28px;
  }

  .Title h3 {
    font-size: 16px;
  }

  .redirect-button {
    font-size: 12px;
    padding: 8px 16px;
  }

  .section-title {
    font-size: 20px;
  }

  .section-content p {
    font-size: 20px;
  }

  .circle {
    width: 30px;
    height: 30px;
    font-size: 1.4rem;
  }

  .step-text {
    font-size: 15px;
  }

  .team-photo {
    max-width: 150px;
  }
}