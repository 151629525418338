/* Define font-face for Inter font */
@font-face {
  font-family: 'Inter';
  src: url('../public/fonts/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../public/fonts/Inter-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../public/fonts/Inter-Medium.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../public/fonts/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../public/fonts/Inter-Light.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../public/fonts/Inter-ExtraLight.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

.App {
  text-align: center;
  height: 100vh;
  overflow-y: auto;
  background-color: #0e0e0e;
  font-family: 'Inter', sans-serif;
}

.Header {

}

.Title {
  position: relative;
  z-index: 1;
  text-align: center;
}

.Title h1 {
  font-family: 'Inter', sans-serif;
  font-weight: 300;
  background: linear-gradient(to right, white, #5896ee);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 48px;
}

.Title h3 {
  font-family: 'Inter', sans-serif;
  font-weight: 100;
  color: rgba(255, 255, 255, 0.864);
  font-size: 20px;
}

.highlight {
  font-weight: 700;
  color: #73a8f0;
}

.italic {
  font-style: italic;
}

.App {
  text-align: center;
  height: 100vh;
  overflow-y: auto;
  background-color: #0e0e0e;
  font-family: 'Inter', sans-serif;
  margin: 0;
  padding: 0;
}

.Body {
  display: flex;
  height: 100vh;
  position: relative;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.stars {
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent;
  overflow: hidden;
}

.star {
  position: absolute;
  width: 2px;
  height: 2px;
  background: white;
  opacity: 0.8;
  border-radius: 50%;
}

.star-blink {
  animation: star-blink-animation 5s linear infinite;
}

.star-move {
  animation: star-move-animation 30s linear infinite;
}

@keyframes star-blink-animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes star-move-animation {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
  100% {
    transform: translateY(var(--translate-y));
    opacity: 0;
  }
}

.email-input {
  margin-top: 20px;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 10px;
  width: 400px;
  background-color: #1a1a1a;
  border: 1px solid #5896ee27;
  color: white;
}

.submit-button {
  margin-top: 10px;
  margin-left: 0.5rem;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 25px;
  background-color: #5896ee;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #4178c0;
}

.popup {
  position: fixed;
  top: 20px;
  right: -10rem;
  transform: translateX(-50%);
  padding: 15px 30px;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  z-index: 1000;
  opacity: 0.9;
  transition: opacity 0.5s ease;
}

.popup.success {
  background-color: #4178c0;
}

.popup.error {
  background-color: #8a231c;
}

.popup.hidden {
  opacity: 0;
}