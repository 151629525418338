.Title {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.Title h1 {
  font-family: 'Inter', sans-serif;
  font-weight: 300;
  background: linear-gradient(to right, white, #5896ee);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 48px;
}

.Title h3 {
  font-family: 'Inter', sans-serif;
  font-weight: 100;
  color: rgba(255, 255, 255, 0.864);
  font-size: 20px;
}

.highlight {
  font-weight: 700;
  color: #73a8f0;
}

.italic {
  font-style: italic;
}

.App {
  text-align: center;
  height: 100vh;
  overflow-y: auto;
  background-color: #0e0e0e;
  font-family: 'Inter', sans-serif;
  margin: 0;
  padding: 0;
}

.Body {
  display: flex;
  height: 100vh;
  position: relative;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.stars {
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent;
  overflow: hidden;
  top: 0;
  left: 0;
  pointer-events: none;
}

.star {
  position: absolute;
  width: 2px;
  height: 2px;
  background: white;
  opacity: 0.8;
  border-radius: 50%;
}

.star-blink {
  animation: star-blink-animation 5s linear infinite;
}

.star-move {
  animation: star-move-animation 30s linear infinite;
}

@keyframes star-blink-animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes star-move-animation {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
  100% {
    transform: translateY(var(--translate-y));
    opacity: 0;
  }
}

.email-input {
  width: 100%;
  max-width: 300px;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 15px;
  box-sizing: border-box;
}

.submit-button {
  width: 100%;
  max-width: 300px;
  padding: 10px;
  margin: 5px 0;
  background-color: #5896ee;
  color: white;
  border: none;
  border-radius: 15px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #4178c0;
}

.popup {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  border-radius: 5px;
  color: white;
  z-index: 2; /* Ensure popup is above other elements */
}

.popup.success {
  background-color: #4178c0;
}

.popup.error {
  background-color: #8a231c;
}

.popup.hidden {
  display: none;
}
