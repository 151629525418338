/* Generic Style */
.Title {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.Title h1 {
  font-family: 'Inter', sans-serif;
  font-weight: 300;
  background: linear-gradient(to right, white, #5896ee);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 48px;
}

.Title h3 {
  font-family: 'Inter', sans-serif;
  font-weight: 100;
  color: rgba(255, 255, 255, 0.864);
  font-size: 20px;
}

.highlight {
  font-weight: 700;
  color: #73a8f0;
}

.italic {
  font-style: italic;
}

.App {
  text-align: center;
  height: 100vh;
  overflow-y: auto;
  background-color: #0e0e0e;
  font-family: 'Inter', sans-serif;
  margin: 0;
  padding: 0;
}

/* Left Menu Section */
.leftMenu {
  width: 210px;
  background-color: #5896ee;
  padding: 20px;
  position: fixed;
  height: 100%;
  left: 0;
  top: 0;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  text-align: left;
}

.leftMenu .logo {
  display: block;
  margin: 0 auto 5rem auto;
  width: 100%;
  max-width: 150px;
}

.leftMenu section {
  margin-bottom: 20px;
}

.leftMenu section h2 {
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 5px;
}

.leftMenu section hr {
  border: 0;
  height: 1px;
  background: #333;
  margin-bottom: 10px;
}

.leftMenu ul {
  list-style-type: none;
  padding: 0;
}

.leftMenu ul li {
  margin-bottom: 10px;
}

.leftMenu ul li a {
  text-decoration: none;
  color: white;
  font-weight: bold;
}

.leftMenu ul li a:hover {
  color: rgb(32, 32, 32);
}

/* Body Section */
.Body {
  margin-left: 230px;
  padding: 20px;
  position: relative;
}

.topText {
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px;
  font-size: 30px;
  font-weight: lighter;
  color: white;
}

.squaresContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 70px;
}

.squareContainer {
  display: flex;
  gap: 10px;
  margin-top: 0;
}

.square {
  position: relative;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.square h2 {
  position: absolute;
  top: 0;
  left: 10px;
  font-size: 20px;
  font-weight: normal;
  color: #222222;
}

.square p {
  font-size: 20px;
  font-weight: bold;
  color: #222222;
}